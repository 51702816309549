import PropTypes from "prop-types"
import React from "react"

const SmarterWorkingVideo = ({ title }) => (
  <div style={{
    background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
  }}>
    <h3 style={{
      color: `#fff`,
      textAlign: `center`,
      fontFamily: `Avenir`,
      fontWeight: `900`,
      fontSize: `3rem`,
      padding: `2rem`,
      margin: `0`
    }}>{title}</h3>
    <div className="embed-responsive embed-responsive-16by9">
      <iframe title="Smarter Working Video" src="https://player.vimeo.com/video/367961332" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
    </div>
  </div>
)

SmarterWorkingVideo.propTypes = {
  title: PropTypes.string,
}

SmarterWorkingVideo.defaultProps = {
  title: `Smarter Working`,
}

export default SmarterWorkingVideo