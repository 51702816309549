import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import PrimaryButton from "../components/primary-button"
import SmarterWorkingVideo from "../components/smarter-working-video"

const SmarterWorkingAwardsPage = () => (
  <Background>
    <Layout>
      <Seo title="Smarter Working Awards" />
      <Container>
        <PageTitle text="Smarter Working Awards" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`,
          paddingBottom: `4rem`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Reimagined</span>
          </div>
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div class="row" style={{ padding: `2rem`}}>
              <div class="col-sm">
                <p>The <strong>Public Sector Paperless Awards</strong> were a unique celebration of outstanding public sector digital transformation and grew to become one of the most anticipated annual awards nights to honour government innovation.</p>
                <p>At GovNewsDirect, we know that the public sector is in a constant state of change and, to ensure we are keeping up with this digital endeavours and evolution, we feel it is even more important to keep our awards celebrations in line with the exciting innovations happening across this space. Now, entering our 4th year, and in the spirit of striving for bigger and better, we are taking our programme to new heights. We know that smarter working is now an imperative in government, and that this concept goes much further than solely becoming paperless; it is driving government towards creating a more agile working culture, with modern environments and flexible working standards.</p>
              </div>
              <div class="col-sm">
                <p>The Cabinet Offices’ <strong>Smarter Working Awards</strong> recognised and celebrated transformation programmes aligned with the Government’s Smarting Working programme.</p>
                <p>In line with these goals to heighten excellence, we are honoured to announce our new and unique partnership with the Government’s Smarter Working Programme.</p>
                <p>As part of an executive agency under the Cabinet Office, the Smarter Working team will join us to judge, showcase and celebrate transformation excellence in 2020. We will deliver another amazing programme - newly coined <strong>Smarter Working Live</strong></p>
                <PrimaryButton to="/enter/" text="How to Enter Smarter Working Live" />
              </div>
            </div>
          </Container>
        </div>
        <SmarterWorkingVideo />
        <div style={{marginTop: `100px`}}></div>
      </Container>
    </Layout>
  </Background>
)

export default SmarterWorkingAwardsPage
